// middleware/auth.js
export default defineNuxtRouteMiddleware((to, from) => {
  // Replace 'myCookie' with the actual name of your cookie
  const myCookie = useCookie("authenticated").value;

  // Check if the cookie is undefined or empty
  if (!myCookie) {
    // Redirect to the home page
    return navigateTo("/");
  }
});
